import { graphql, useStaticQuery } from "gatsby";
import { createGlobalStyle } from "styled-components"
import React from "react";
import Helmet from "react-helmet";

export default function GlobalStyles({rem, contrast, dyslexia}) {

  const data = useStaticQuery(graphql`
    query settingsQuery{
        wp {
        acfOptionsTypografie {
            typografie {
            headerfonts{
                navigatiefont
            }
            standaardfonts{
                h1font
                h2font
                h3font
                textfont
            }
            }
        }
        acfOptionsKleuren {
            kleuren {
            header {
                background
                textcolor
            }
            footer {
                background
                textcolor
            }
            textcolors {
                h1color
                h2color
                h3color
                textcolor
            }
            kleurpalet {
                accentcolor
                bgcolor
                primarycolor
                secondarycolor
                lightcolor
            }
            }
        }
        }
        }`)

  // Palet
  const primary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.primarycolor
  const secondary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.secondarycolor
  const accent = data.wp.acfOptionsKleuren.kleuren.kleurpalet.accentcolor
  const text = data.wp.acfOptionsKleuren.kleuren.textcolors.textcolor
  const background = data.wp.acfOptionsKleuren.kleuren.kleurpalet.bgcolor
  const light = data.wp.acfOptionsKleuren.kleuren.kleurpalet.lightcolor

  // Header
  const headerBackground = data.wp.acfOptionsKleuren.kleuren.header.background
  const headerText = data.wp.acfOptionsKleuren.kleuren.header.textcolor

  // Footer
  const footerBackground = data.wp.acfOptionsKleuren.kleuren.footer.background
  const footerText = data.wp.acfOptionsKleuren.kleuren.footer.textcolor

  // Headings
  const h1 = data.wp.acfOptionsKleuren.kleuren.textcolors.h1color
  const h2 = data.wp.acfOptionsKleuren.kleuren.textcolors.h2color
  const h3 = data.wp.acfOptionsKleuren.kleuren.textcolors.h3color

  const GlobalStyle = createGlobalStyle`

    @font-face {
      font-family: clfallback;
      src: url(/fonts/open_dyslexic/OpenDyslexic-Regular.otf) format('opentype')
    }

    @font-face {
      font-family: oda;
      src: url(/fonts/open_dyslexic/OpenDyslexic-Regular.otf) format('opentype');
      font-weight: 400;
      font-style: normal
    }

    @font-face {
      font-family: oda;
      src: url(/fonts/open_dyslexic/OpenDyslexic-Italic.otf) format('opentype');
      font-weight: 400;
      font-style: italic
    }

    @font-face {
      font-family: oda;
      src: url(/fonts/open_dyslexic/OpenDyslexic-Bold.otf) format('opentype');
      font-weight: 700;
      font-style: normal
    }

    @font-face {
      font-family: oda;
      src: url(/fonts/open_dyslexic/OpenDyslexic-BoldItalic.otf) format('opentype');
      font-weight: 700;
      font-style: italic
    }

    :root {
      --primary-color: ${primary};
      --secondary-color: ${secondary};
      --accent-color: ${accent};
      --text-color: ${text};
      --bg-color: ${background};
      --light-color: ${light};
      --light-gray-color: #E9E9E9;
      --light-bg-color: #FDFBFF;
      --standard-font: ${rem};
    }
    html{
      ${contrast ? 'filter: grayscale(0) contrast(2)' : ''}
    }
    body{
      ${dyslexia ? 'font-family: clfallback,oda,ff-dagny-web-pro,sans-serif;' : 'font-family: "ff-dagny-web-pro", sans-serif;'}
    }
    h1{
      color: ${h1};
      ${dyslexia ? 'font-family: clfallback,oda,ff-dagny-web-pro,sans-serif;' : 'font-family: "ff-dagny-web-pro", sans-serif;'}
      font-weight: 700;
      font-style: normal;
    }
    h2{
      color: ${h2};
      ${dyslexia ? 'font-family: clfallback,oda,ff-dagny-web-pro,sans-serif;' : 'font-family: "ff-dagny-web-pro", sans-serif;'}
      font-weight: 700;
      font-style: normal;
    }
    h3{
      color: ${h3};
      ${dyslexia ? 'font-family: clfallback,oda,ff-dagny-web-pro,sans-serif;' : 'font-family: "ff-dagny-web-pro", sans-serif;'}
      font-weight: 700;
      font-style: normal;
    }
    h4{
      color: ${h3};
      ${dyslexia ? 'font-family: clfallback,oda,ff-dagny-web-pro,sans-serif;' : 'font-family: "ff-dagny-web-pro", sans-serif;'}
      font-weight: 700;
      font-style: normal;
    }
    .headerClass{
      background-color: ${headerBackground};
      color: ${headerText};
    }
    .footerClass{
      background-color: ${footerBackground};
      color: ${footerText};
    }
    .footerClass h1,  .footerClass h2,  .footerClass h3,  .footerClass h4{
      color: ${footerText};
    }
    .navigation{
      ${dyslexia ? 'font-family: clfallback,oda,ff-dagny-web-pro,sans-serif;' : 'font-family: "ff-dagny-web-pro", sans-serif;'}
    }
  `

  return (
    <>
      <GlobalStyle />
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/oxr8llv.css"></link>
      </Helmet>
    </>
  )
}