import React, { useContext, useEffect } from "react";
import Container from "../../atoms/Container";
import { Link, navigate } from "gatsby";
import skip from "../../../images/icons/skip.svg"
import sound from "../../../images/icons/sound.svg"
import contrast from "../../../images/icons/contrast.svg"
import dyslexie from "../../../images/icons/dyslexie.svg"
import { useHeaderSettings } from "../../../hooks/useHeaderSettings";
import PageContext from "../../../store/page-context";
import { ReactSVG } from "react-svg";
import TextToSpeech from "../../atoms/TextToSpeech";
import smoothScroll from "../../../functions/smoothScroll";

export default function MiniHeader({ subHandler }) {

    const headerSettings = useHeaderSettings()
    const miniMenu = headerSettings.wpMenu.menuItems.nodes

    const ctx = useContext(PageContext);

    useEffect(()=>{
            const anchors = document.querySelectorAll('a[href^="#"]');
            console.log(anchors)
            anchors.forEach(function(anchor) {
              anchor.addEventListener('click', function(event) {
                event.preventDefault();
                const target = anchor.getAttribute('href').substring(1);
                smoothScroll(target);
              });
            });
    }, [])

    return (
        <div className="st-header__mini" id="headerMini">
            <div className="st-header__mini--bg">
                <Container styles={'flex justify-between'}>
                    <div className="st-header__mini-controls">
                        <a href="#main-content" className="st-controls__link">
                            <ReactSVG src={skip} />
                            <span>Skip naar de inhoud</span>
                        </a>
                        {/* <a className="st-controls__link">
                            <ReactSVG src={sound} />
                            <span>Website voorlezen</span>
                            <TextToSpeech text={'Test teksten'} />
                        </a> */}
                        <a href="#" className="st-controls__link" onClick={ctx.toggleContrast} onKeyDown={(e) => (e.key === 'Enter' || e.keyCode === 13 ? ctx.toggleContrast : null)}>
                            <ReactSVG src={contrast} />
                            <span>Contrast {ctx.contrast ? 'verlagen' : 'verhogen'}</span>
                        </a>
                        <a href="#" className="st-controls__link" onClick={ctx.toggleDyslexia} onKeyDown={(e) => (e.key === 'Enter' || e.keyCode === 13 ? ctx.toggleDyslexia : null)}>
                            <ReactSVG src={dyslexie} />
                            <span>Dyslexie lettertype {ctx.dyslexia ? 'uit' : 'aan'}</span>
                        </a>
                    </div>
                    <div className="st-header__mini--menu">
                        <nav>
                            <ul>
                                {miniMenu.map((m, i) => {
                                return (
                                    <li key={i} onClick={()=>{ctx.changeSearch(false)}}><Link to={m.uri}>{m.label}</Link></li>
                                )
                            })}
                            </ul>
                        </nav>
                    </div>
                </Container>
            </div>
        </div>
    )
}