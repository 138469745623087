import React from 'react'

const PageContext = React.createContext({
    scale: '',
    change: '',
    contrast: '',
    toggleContrast: '',
    search: '',
    changeSearch: '',
    dyslexia: '',
    toggleDyslexia: '',
});

export default PageContext;