import React, { useCallback, useRef } from "react";
import Container from "../../atoms/Container";
import './footer.css'
import { useAlgemeen } from "../../../hooks/useAlgemeen";
import { useFooter } from "../../../hooks/useFooter";
import { useMogelijkheden } from "../../../hooks/useMogelijkheden";
import { Link } from "gatsby";
import insta from '../../../images/icons/insta.svg'
import li from '../../../images/icons/li-dark.svg'
import useCustomLinkHandler from "../../../functions/customLinkHandler";
import { navigate } from "gatsby"


export default function Footer(){

    const contact = useAlgemeen()
    const footer = useFooter()
    const mogelijkheden = useMogelijkheden()

    const containerRef = useRef(null);

    const phone = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M14.6463 12.22C14.6463 12.46 14.593 12.7067 14.4797 12.9467C14.3663 13.1867 14.2197 13.4134 14.0263 13.6267C13.6997 13.9867 13.3397 14.2467 12.933 14.4134C12.533 14.58 12.0997 14.6667 11.633 14.6667C10.953 14.6667 10.2263 14.5067 9.45967 14.18C8.69301 13.8534 7.92634 13.4134 7.16634 12.86C6.39967 12.3 5.67301 11.68 4.97967 10.9934C4.29301 10.3 3.67301 9.57337 3.11967 8.81337C2.57301 8.05337 2.13301 7.29337 1.81301 6.54004C1.49301 5.78004 1.33301 5.05337 1.33301 4.36004C1.33301 3.90671 1.41301 3.47337 1.57301 3.07337C1.73301 2.66671 1.98634 2.29337 2.33967 1.96004C2.76634 1.54004 3.23301 1.33337 3.72634 1.33337C3.91301 1.33337 4.09967 1.37337 4.26634 1.45337C4.43967 1.53337 4.59301 1.65337 4.71301 1.82671L6.25967 4.00671C6.37967 4.17337 6.46634 4.32671 6.52634 4.47337C6.58634 4.61337 6.61967 4.75337 6.61967 4.88004C6.61967 5.04004 6.57301 5.20004 6.47967 5.35337C6.39301 5.50671 6.26634 5.66671 6.10634 5.82671L5.59967 6.35337C5.52634 6.42671 5.49301 6.51337 5.49301 6.62004C5.49301 6.67337 5.49967 6.72004 5.51301 6.77337C5.53301 6.82671 5.55301 6.86671 5.56634 6.90671C5.68634 7.12671 5.89301 7.41337 6.18634 7.76004C6.48634 8.10671 6.80634 8.46004 7.15301 8.81337C7.51301 9.16671 7.85967 9.49337 8.21301 9.79337C8.55967 10.0867 8.84634 10.2867 9.07301 10.4067C9.10634 10.42 9.14634 10.44 9.19301 10.46C9.24634 10.48 9.29967 10.4867 9.35967 10.4867C9.47301 10.4867 9.55967 10.4467 9.63301 10.3734L10.1397 9.87337C10.3063 9.70671 10.4663 9.58004 10.6197 9.50004C10.773 9.40671 10.9263 9.36004 11.093 9.36004C11.2197 9.36004 11.353 9.38671 11.4997 9.44671C11.6463 9.50671 11.7997 9.59337 11.9663 9.70671L14.173 11.2734C14.3463 11.3934 14.4663 11.5334 14.5397 11.7C14.6063 11.8667 14.6463 12.0334 14.6463 12.22Z" stroke="#ED7100" stroke-width="1.5" stroke-miterlimit="10"/></svg>
    const email = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M11.333 13.6667H4.66634C2.66634 13.6667 1.33301 12.6667 1.33301 10.3334V5.66671C1.33301 3.33337 2.66634 2.33337 4.66634 2.33337H11.333C13.333 2.33337 14.6663 3.33337 14.6663 5.66671V10.3334C14.6663 12.6667 13.333 13.6667 11.333 13.6667Z" stroke="#ED7100" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.3337 6L9.24699 7.66667C8.56032 8.21333 7.43366 8.21333 6.74699 7.66667L4.66699 6" stroke="#ED7100" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
    
    const date = new Date()
    const year = date.getFullYear()

    const handleLinkClick = useCallback((e) => {
        e.preventDefault();
        navigate(e.target.href)
      }, []);
    
      useCustomLinkHandler(containerRef, handleLinkClick);

    return(
        <footer className="st-footer">
            <Container>
                <div className="st-footer__grid">
                    <div>
                        <h4>Contactgegevens</h4>
                        <div className="st-footer__content" dangerouslySetInnerHTML={{__html: contact.wp.acfOptionsAlgemeneInstellingen.gegevens.contactGegevens.adresGegevens}}></div>
                        <div className="st-footer__contact">
                            <a href={`tel:${contact.wp.acfOptionsAlgemeneInstellingen.gegevens.contactGegevens.telefoonnummer.replaceAll(' ', '')}`}>{phone}{contact.wp.acfOptionsAlgemeneInstellingen.gegevens.contactGegevens.telefoonnummer}</a>
                            <a href={`mailto:${contact.wp.acfOptionsAlgemeneInstellingen.gegevens.contactGegevens.eMailadres}`}>{email}{contact.wp.acfOptionsAlgemeneInstellingen.gegevens.contactGegevens.eMailadres}</a>
                        </div>
                    </div>
                    <div>
                        <h4>Links</h4>
                        <div className="st-footer__content"  ref={containerRef} dangerouslySetInnerHTML={{__html: footer.wp.acfOptionsFooter.footer.links}}></div>
                    </div>
                    <div>
                        <h4>Mogelijkheden</h4>
                        <div className="st-footer__content">
                            {mogelijkheden.allWpMogelijkheid.edges.map((m, i)=> <Link to={m.node.uri}>{m.node.title}</Link>)}
                        </div>
                    </div>
                    <div>
                        <h4>Volg ons</h4>
                        <div className="st-footer__content st-footer__socials">
                            <a href={contact.wp.acfOptionsAlgemeneInstellingen.gegevens.socials.instagram} target="_blank"><img src={insta} />@wijzijnstark</a>
                            <a href={contact.wp.acfOptionsAlgemeneInstellingen.gegevens.socials.linkedin} target="_blank"><img src={li} />@wijzijnstark</a>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="st-footer__lower">
                    <div className="st-footer__copy"><span>&copy;{year}</span><span>Website door: <a href="https://hq-online.nl/" target="_blank">HQ Online</a></span></div>
                    <div className="st-footer__privacy" dangerouslySetInnerHTML={{__html: footer.wp.acfOptionsFooter.footer.privacyMenu}}></div>
                </div>
            </Container>
        </footer>
    )
}