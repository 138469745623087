import { useStaticQuery, graphql } from "gatsby"

export const useAlgemeen = () =>{
   
    const data = useStaticQuery(graphql`
    query AlgemeenQuery{
        wp {
            acfOptionsAlgemeneInstellingen {
              gegevens {
                contactGegevens {
                  telefoonnummer
                  eMailadres
                  adresGegevens
                }
                socials {
                  linkedin
                  instagram
                  facebook
                }
              }
            }
          }
    }
    `)

    return data
}