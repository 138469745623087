import React from "react";
import { Link } from "gatsby";
import './buttons.css';

export default function LightButton({ target, label }) {

    return (
        <Link to={target}>
            <div className="st-light__button st-button">
                <span>{label}</span>
            </div>
        </Link>
    )
}