import React, { useEffect, useState } from "react";
import { useSearch } from "../../../hooks/useSearch";
import searchFunction from "../../../functions/searchFuntion";
import { Link } from "gatsby";
import './search.css'
import Container from "../Container";
import highlightTerm from "../../../functions/highlightTerm";
import { useSearchDev } from "../../../hooks/useSearchDev";
import { motion } from "framer-motion"

export default function Search({closeHandler}){

    const [result, setResult] = useState()
    const [term, setTerm] = useState('')
    const [top, setTop] = useState()

    const search = <svg data-v-1eb03338="" xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" aria-label="search" role="img" class="icon" data-v-6522a81a=""><g fill="none" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"><path d="M9 17.25C13.4183 17.25 17 13.6683 17 9.25C17 4.83172 13.4183 1.25 9 1.25C4.58172 1.25 1 4.83172 1 9.25C1 13.6683 4.58172 17.25 9 17.25Z"></path><path d="M19 19.25L15 15.25"></path></g></svg>
    const close = <svg data-v-1eb03338="" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" aria-label="cross-circle-fill" role="img" class="icon ds-color-grey-1300" data-v-6522a81a=""><g stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-miterlimit="10"></path><path d="M8 8L14 14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14 8L8 14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
    
    const devData = useSearchDev()
    const liveData = useSearch()

    const data = process.env.GATSBY_VERCEL_ENV !== 'production' ? devData : liveData

    const keyHandler = (e) =>{

        if(e.target.value.length > 0){
            const res = searchFunction(e.target.value, data)
            setResult(res)
        }else{
            setResult([])
        }

        setTerm(e.target.value)

    }

    const resultHandler = () =>{
        setResult([])
        closeHandler(false)
    }

    useEffect(()=>{
        const handleScroll = () => {
            const header = document.getElementById('header').getBoundingClientRect();
            const headerMini = document.getElementById('headerMini').getBoundingClientRect();
    
            let distance = header.height
    
            if(headerMini.bottom > 0){
                distance += headerMini.bottom
            }

            distance -= 10
    
            setTop(distance)
        }

        handleScroll();

        window.addEventListener('scroll', handleScroll)
       
        return () => window.removeEventListener('scroll', handleScroll)
    },[])

    const removeHandler = () => {
        setResult([])
        setTerm('')
    }

    return(
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: .5, ease: 'easeInOut', opacity: {duration: .1} }} className="st-search" style={{'--header-height': `${top}px`}}>
            <Container>
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: .2, delay: 0, ease: 'easeInOut'}}  className="st-search__input--container">
                <span className="st-search__input--icon">{search}</span>
                <input type="text" placeholder="Zoeken" value={term} onChange={keyHandler}></input>
                {term.length > 0 ? <span className="st-search__input--close" onClick={removeHandler}>{close}</span> : null}
            </motion.div>
            {result?.length > 0 ?
                <div className="st-search__results">
                    {result.map((r,i)=>{
                        return(
                            <div key={i} className="st-search__result">
                                <Link to={r.uri} onClick={resultHandler}>
                                    <h3>{highlightTerm(r.title, term)}</h3>
                                    <span className="uri">{highlightTerm(r.uri, term)}</span>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            : null}
            </Container>
        </motion.div>
    )
}