import React, { useEffect, useState } from "react"
import Footer from "../../molecules/Footer"
import GlobalStyles from "../GlobalStyles"
import PageContext from "../../../store/page-context"
import Accessibility from "../../atoms/Accessibility"
import MegaHeader from "../../molecules/Header/megaHeader"
import MiniHeader from "../../molecules/Header/miniHeader"
import StarkFitHeader from "../../molecules/Header/starkFitHeader"
import { AnimatePresence } from "framer-motion"

const Layout = ({ children, location }) => {

  const [scale, setScale] = useState(1)
  const [contrast, setContrast] = useState(false)
  const [activeSearch, setActiveSearch] = useState(false)
  const [dyslexia, setDyslexia] = useState(false)
  const [starkFit, setStarkFit] = useState(false)

  useEffect(() => {
      if (location.pathname?.includes('stark-en-fit') || window.location.pathname.includes('stark-en-fit')) {
        setStarkFit(true)
      } else {
        setStarkFit(false)
      }
  }, [location])

  const scaleHandler = (e) => {
    setScale(e)
  }

  const contrastHandler = (e) => {
    setContrast(!contrast)
  }

  const searchHandler = (e) => {
    setActiveSearch(e)
  }

  const toggleDyslexia = (e) => {
    setDyslexia(!dyslexia)
  }

  return (
    <PageContext.Provider
      value={{
        scale: scale,
        change: scaleHandler,
        contrast: contrast,
        toggleContrast: contrastHandler,
        search: activeSearch,
        changeSearch: searchHandler,
        dyslexia: dyslexia,
        toggleDyslexia: toggleDyslexia,
      }}
    >
      <GlobalStyles rem={scale} contrast={contrast} dyslexia={dyslexia} />
      <MiniHeader />
      {
        starkFit ?
          <StarkFitHeader />
          :
          <MegaHeader />
      }
      <main>
        <AnimatePresence mode="wait">
        {children}
        </AnimatePresence>
      </main>
      <Footer />
      <Accessibility />
    </PageContext.Provider>
  )
}

export default Layout
