import React from "react";

export default function highlightTerm(text, highlight) {
    
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

    return parts.map((part, i) => 
        <span key={i} className={`${part.toLowerCase() === highlight.toLowerCase() ? 'highlight' : ''}`}>
          { part }
        </span>)
  }