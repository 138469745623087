export default function smoothScroll(target) {
    const element = document.getElementById(target);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
      element.setAttribute('tabindex', '-1');
      setTimeout(()=>{
        element.focus();
      }, 200)
    }
  }