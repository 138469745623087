import { useStaticQuery, graphql } from "gatsby"

export const useSearchDev = () => {
    const data = useStaticQuery(graphql`
        query SearchDevQuery{
            allWpWooverzoek {
                edges {
                  node {
                    uri
                    title
                    tags {
                      nodes {
                        name
                      }
                    }
                  }
                }
            }
            allWpVerhaal{
                edges {
                  node {
                    uri
                    title
                    tags {
                      nodes {
                        name
                      }
                    }
                  }
                }
            }
            allWpPost{
                edges {
                  node {
                    uri
                    title
                    tags {
                      nodes {
                        name
                      }
                    }
                  }
                }
            }
            allWpMogelijkheid{
                edges {
                  node {
                    title
                    uri
                    tags {
                      nodes {
                        name
                      }
                    }
                  }
                }
            }
            allWpPage {
              edges {
                node {
                  title
                  uri
                  tags {
                    nodes {
                      name
                    }
                  }
                }
              }
          }
        }
      `)
    return data
}
