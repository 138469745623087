import { useStaticQuery, graphql } from "gatsby"

export const useMogelijkheden = () =>{
   
    const data = useStaticQuery(graphql`
    query MogelijkhedenQuery{
        allWpMogelijkheid {
            edges {
              node {
                databaseId
                title
                uri
              }
            }
          }
    }
    `)

    return data
}