import React, { useEffect, useState } from "react";
import Container from "../../atoms/Container";
import { Link } from "gatsby";
import starkfitlogo from "../../../images/stark-en-fit.svg"
import PrimaryButton from "../../atoms/Buttons/PrimaryButton"
import LightButton from "../../atoms/Buttons/LightButton"

export default function StarkFitHeader() {

    const [top, setTop] = useState(false)
    const [menu, setMenu] = useState(false)

    useEffect(() => {

        const handleScroll = () => {
            if (window.pageYOffset > 65) {
                setTop(true)
            } else if (window.pageYOffset == 0) {
                setTop(false)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)

    }, [])

    return (
        <header className={`st-header__mega ${top ? 'scrolled' : ''} st-fit`} id="header">
            <Container styles={'flex justify-between items-center'}>
                <Link to="#"><img src={starkfitlogo} /></Link>
                <div className="st-header__mega--menu">
                    <nav aria-labelledby="mainmenulabel" className={`${menu ? 'open' : ''}`}>
                        <h2 id="mainmenylabel" className="hidden">Hoofdmenu</h2>
                        <ul>
                            <li onClick={()=>{setMenu(false)}}>
                                <a href="#section-1">Wat is stark en fit?</a>
                            </li>
                            <li onClick={()=>{setMenu(false)}}>
                                <a href="#section-2">Voor wie is het?</a>
                            </li>
                            <li onClick={()=>{setMenu(false)}}>
                                <a href="#section-3">Wat we doen</a>
                            </li>
                            <li onClick={()=>{setMenu(false)}}><PrimaryButton label={'Contact opnemen'} target={'#section-4'} /></li>
                            <li onClick={()=>{setMenu(false)}}><LightButton label={'Naar Stark'} target={'/'} /></li>
                        </ul>
                    </nav>
                </div>
                <div className="st-menu__mobile--controls">
                    <div className={`st-menu__toggle ${menu ? 'active' : ''}`} onClick={() => { setMenu(!menu) }}>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </Container>
        </header>
    )
}