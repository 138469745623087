// useCustomLinkHandler.js

import { useEffect } from 'react';

const useCustomLinkHandler = (ref, handler) => {
  useEffect(() => {
    // Check if the ref current is available
    if (ref.current) {
        console.log(ref.current)
      // Get all <a> tags within the container
      const links = ref.current.querySelectorAll('a');

      // Attach the event listener to each link
      links.forEach(link => {
        link.addEventListener('click', handler);
      });

      // Cleanup function to remove event listeners
      return () => {
        links.forEach(link => {
          link.removeEventListener('click', handler);
        });
      };
    }
  }, [ref, handler]); // Re-run when ref or handler changes
};

export default useCustomLinkHandler;
