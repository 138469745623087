import { Link } from "gatsby"
import React, {useContext, useEffect, useState} from "react"
import Container from "../../atoms/Container"
import Navigation from "../../atoms/Navigation"
import { useHeaderSettings } from "../../../hooks/useHeaderSettings"
import './header.css'
import PageContext from "../../../store/page-context"

const MegaHeader = () => {

    const [top, setTop] = useState(false)

    const header = useHeaderSettings()
    const logo = header.wp.siteLogo

    const ctx = useContext(PageContext)

    useEffect(()=>{

        const handleScroll = () => {
            if (window.pageYOffset > 65){
                setTop(true)
            } else if(window.pageYOffset == 0){
                setTop(false)
            }       
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)

    }, [])

    return (
        <header className={`st-header__mega ${top ? 'scrolled' : ''}`} id="header">
            <Container styles={'flex justify-between items-center'}>
                <Link to="/" onClick={()=>{ctx.changeSearch(false)}}><img className="st-header__logo" width={`${logo?.width}px`} height={`${logo?.height}px`} src={logo?.localFile.publicURL} /></Link>
                <Navigation />
            </Container>
        </header>
    )
}

export default MegaHeader
