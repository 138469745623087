import { Link } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { useNav } from "../../../hooks/useNav";
import './navigation.css'
import search from '../../../images/icons/search.svg'
import Search from "../Search";
import PageContext from "../../../store/page-context";
import { ReactSVG } from "react-svg";
import { AnimatePresence } from "framer-motion";

export default function Navigation() {

    const [menu, setMenu] = useState(false)

    const data = useNav()

    const ctx = useContext(PageContext)

    const toggleSearch  = () => {
        ctx.changeSearch(!ctx.search)
    }

    const closeHandler = (e) => {
        ctx.changeSearch(e)
    }

    return (
        <div className="st-header__mega--menu">
            <nav aria-labelledby="mainmenulabel" className={`${menu ? 'open' : ''}`}>
                <h2 id="mainmenulabel" className="hidden">Hoofdmenu</h2>
                <ul>
                    {data.wpMenu.menuItems.nodes.map((item, i) => {
                        return (
                            <li key={i} className={item.cssClasses.map((item) => item)} onClick={()=>{setMenu(false)}}>
                                {item.cssClasses.includes('search') ?
                                    <div className={`st-search__item ${ctx.search ? 'active' : ''}`}>
                                        <a href="#" onKeyDown={(e) => (e.key === 'Enter' || e.keyCode === 13 ? toggleSearch() : null)}>
                                            <ReactSVG onClick={toggleSearch} src={search} />
                                        </a>
                                        <AnimatePresence mode="wait">
                                            {ctx.search ? <Search closeHandler={closeHandler} /> : null}
                                        </AnimatePresence>
                                    </div>
                                :
                                    <Link to={item.uri} onClick={()=>{ctx.changeSearch(false)}} activeClassName={`${item.cssClasses.includes('button') ? '' : 'text-primary' }`} >
                                        {item.label}
                                    </Link>
                                }
                            </li>
                        )
                    })}
                </ul>
            </nav>
            <div className="st-menu__mobile--controls">
                <div>
                    <ReactSVG onClick={toggleSearch} src={search} />
                    {ctx.search ? <Search closeHandler={closeHandler} /> : null}
                </div>
                <div className={`st-menu__toggle ${menu ? 'active' : ''}`} onClick={()=>{setMenu(!menu)}}>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    )
}