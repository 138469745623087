import React from "react";
import { Link } from "gatsby";
import './buttons.css';

export default function PrimaryButton({ target, label }) {

    if(target !== ''){
        return (
            <Link to={target}>
                <div className="st-primary__button st-button">
                    <span>{label}</span>
                </div>
            </Link>
        )
    }else{
        return (
            <div className="st-primary__button st-button">
                <span>{label}</span>
            </div>
        )
    }
    
}