import { useStaticQuery, graphql } from "gatsby"

export const useHeaderSettings = () =>{
   
    const data = useStaticQuery(graphql`
    query HeaderSettingsQuery{
        wpMenu(locations: {eq: EXTRA_MENU}) {
            id
            menuItems {
              nodes {
                uri
                label
              }
            }
          }
          wp {
            siteLogo {
              height
              width
              localFile {
                publicURL
              }
            }
        }
    }
    `)

    return data
}