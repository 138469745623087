import { useStaticQuery, graphql } from "gatsby"

export const useFooter = () =>{
   
    const data = useStaticQuery(graphql`
    query FooterQuery{
        wp {
            acfOptionsFooter {
             footer {
                links
                privacyMenu
             }
            }
          }
    }
    `)

    return data
}