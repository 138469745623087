export default function searchFunction(term, data) {
    
    const flattenData = (data) => {
        let results = [];
        Object.values(data).forEach(item => {
            item.edges.forEach(edge => {
                results.push(edge.node);
            });
        });
        return results;
    };

    

    const filteredNodes = flattenData(data).filter(node =>
        node.title.toLowerCase().includes(term.toLowerCase())
        ||
        node.uri.toLowerCase().includes(term.toLowerCase())
        ||
        node.tags?.nodes.find(a => a.name.toLowerCase().includes(term.toLowerCase()))
    );

    return filteredNodes

}