import { useStaticQuery, graphql } from "gatsby"

export const useSearch = () => {
  const data = useStaticQuery(graphql`
      query SearchQuery{
            allWpWooverzoek(filter: {seo: {metaRobotsNoindex: {ne: "noindex"}}}) {
              edges {
              node {
                  uri
                  title
                  tags {
                    nodes {
                      name
                    }
                  }
              }
              }
          }
          allWpVerhaal(filter: {seo: {metaRobotsNoindex: {ne: "noindex"}}}) {
              edges {
              node {
                  uri
                  title
                  tags {
                    nodes {
                      name
                    }
                  }
              }
              }
          }
          allWpPost(filter: {seo: {metaRobotsNoindex: {ne: "noindex"}}}) {
              edges {
              node {
                  uri
                  title
                  tags {
                    nodes {
                      name
                    }
                  }
              }
              }
          }
          allWpMogelijkheid(filter: {seo: {metaRobotsNoindex: {ne: "noindex"}}}) {
              edges {
              node {
                  title
                  uri
                  tags {
                    nodes {
                      name
                    }
                  }
              }
              }
          }
          allWpPage(filter: {seo: {metaRobotsNoindex: {ne: "noindex"}}})  {
          edges {
              node {
              title
              uri
              tags {
                nodes {
                  name
                }
              }
              }
          }
      }
          
      }
      `)
  return data
}